:root {
  --color-fail: red;
  --color-success: green; 
}

.bodystyle {
  background-color: rgb(246, 251, 255);
}

.header {
  padding-top: 40px;
}

.mainGrid {
  border: 0px grey;
  padding: 0px 0px;
  text-align: center;
}

.navBar {
  background-color: rgb(52, 64, 94);
  width: 100%;
}

.siteLogo{
  scale: 80%;
  margin-right: 30px;
}

.brand {
  font-size: large;
}

.home {

  margin: 20px 45px;
  color: rgb(194, 226, 255);
  margin-right: 40px
}

.percentInput{
  width: 120px;
  padding: 1px 20px;
  border-radius: 7px;
  text-align: center;
}

.dollarInput{
  width: 120px;
  padding: 0px 0px;
  border-radius: 7px;
  text-align: center;
}

.centred{
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.moneyAddForm{
  width: 150px;
  display: inline-block;
  padding-left: 20px;
}

.totalPercent{
  color: var(--color-fail);
}

.allocationChart{
  display: none;
}

.newAllocationChart{
  display: none;
}


.arrow{
  display: none;
}

.tableSizing{
  width: 100%;
  padding-top: 40px;
}

.pageHeader {
  text-align: center;
  margin-top: 50px;
}

.pageContent {
  text-align: left;
  padding: 50px 20% 0%;
  font-size: medium;
}

.emailForm {
  padding: 10px 20% 10%;
}

.portfolioRebalancer {
  text-align: center;
  margin: 50px 5% 5%;
  border-width: 22px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 5px 10px 20px rgba(0,0,0,.15);

}

.tableHead{
  font-size: small
}

.tableBody{
  font-size: small
}

.form-control-sm{
  font-size:xx-small !important;
}

.subForm-control-sm{
  font-size:10px !important;
  width: 75% !important;
}

.subForm-Error{
  border-color: red !important;
  border-width: 2px !important;
}

.subForm-control-centred{
  text-align: center;
  align-content: right !important;
}

.allocation{
  display: none;
  padding: 5px;
}

.subAllocation{
  display: none;
  padding: 5px;
}

.plus{
  color: rgb(129, 237, 115);
  padding: 11px 6% 2%;
  float: left !important;
}

.mainButton {
  text-align: left !important;
  background-color: #8884d8 !important;
  border: none !important;
}

.subButton {
  text-align: left !important;
  background-color: #83CA9D !important;
  border: none !important;
}

.moneyAdd{
  padding-bottom: 50px !important;
}

.masterRowName {
  text-align: center;
  padding-top: 15px;
}

.cashName {
  padding-left: 70px !important;
}

.total {
  padding-left: 70px !important;
  background-color: rgb(237, 238, 255) !important;
  box-shadow: 0px 0px 20px rgba(0,0,0,.075);
}

.chart{
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  padding-top: 30px;
  filter: drop-shadow(5px 5px 25px rgba(0,0,0,.2));
}

.hardMode{
  font-family: 'Handjet', sans-serif;
  font-size: 36px !important; 
  margin-left: 5px;
  color:#83CA9D;
}


@media only screen and (min-width: 1200px) {


  .allocationChart{
    display: inline-block;
    position:relative;
    margin:auto;
    vertical-align: middle;
  
  }

  .arrow{
    display: inline-block;
    position:relative;
    margin:auto;
    font-size: 70px;
    color: #222735;
  }

  
}

@media only screen and (min-width: 665px) {
  .newAllocationChart{
    display: inline-block;
    position:relative;
    margin:auto;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 550px) {
  .tableSizing{
    font-size: 1rem !important;
  }
  
  .tableHead{
    font-size: medium;
    box-shadow: 5px 10px 20px rgba(0,0,0,.05);
  }

  .tableBody{
    font-size: medium;
  }
  
  .form-control-sm{
    font-size: small !important;
  }

  .allocation{
    display: table-cell;
    padding-top: 15px;
    padding-bottom: 18px;
    text-align: center !important;
  }

  .subAllocation{
    display: table-cell;
    padding: 5px;
  }

}